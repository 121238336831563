import React from "react";
import { Divider } from "../../components/Divider";
import FoundationPage from "../../components/FoundationPage";
import Layout from "../../components/Layout";

export default function LayoutPage({ location }) {
  return (
    <Layout location={location}>
      <FoundationPage
        pageTitle="Layout"
        description="Page frame and grid system are the foundation for creating responsive, well laid out products. Each part of our layout guidance is meant to give you the flexibility to choose the layout that works best for you. Our grid system can be as straightforward or as complex as your design requires."
        link1title="View in Figma"
        link1url="https://www.figma.com/file/08RyCY34fSWYCxwrFWbojr/GDS-%2F-Core-Library?node-id=6337%3A186997"
        tocItems={[
          "MAF vs. Web Components",
          "Grid System Fundamentals",
          "Responsive Breakpoints",
          "Containers",
          "Column Gutters",
        ]}
      >
        <React.Fragment>
          <div className="section" id="maf-vs.-web-components">
            <h2>MAF vs. Web Components</h2>
            <p>
              The technical details of our grid systems vary depending on the
              development platform your project uses. MAF uses a custom grid
              system, which they call their Flex component. Our Web Components
              styles include Bootstrap 5’s grid system, Flexbox utilities, and
              CSS Grid utilities. Both options are powerful ways to layout a
              modern, responsive web application. The principles below should be
              the same, though the particulars may differ slightly in each
              platform.
            </p>
          </div>
          <Divider />
          <div className="section" id="grid-system-fundamentals">
            <h2>Grid System Fundamentals</h2>
            <p>
              Many designers don't about using a grid system when they design.
              This is something we’d like to change at Nielsen. It can create
              issues whenever designs are handed off to development. Using a
              grid system makes responsive web design more straightforward. It
              also helps designers and developers to speak the same language
              during the design and development processes.
            </p>
            <h3>Grid System Best Practices</h3>
            <ul>
              <li>Know your page’s breakpoint and container max width</li>
              <li>Think in rows...then in columns</li>
              <li>Start with a simple column layout like halves or thirds</li>
              <li>
                Stop thinking in fixed widths. Switch to percentages and max
                widths.
              </li>
              <li>Nest your containers and columns</li>
              <li>Every so often, think about smaller and larger screens</li>
            </ul>
          </div>
          <Divider />

          <div className="section" id="responsive-breakpoints">
            <h2>Responsive Breakpoints</h2>
            <p>
              To target different layouts at different browser widths, we set
              CSS breakpoints. Content should shuffle or reframe appropriately
              depending on the user’s browser width.
            </p>
            <p>
              Some of our B2B applications will not work on smaller browser
              widths. Our system will alert the user whenever this is the case.
              Our B2B applications will target xl and xxl browser widths while
              our Panel applications will target desktop, tablet, and mobile
              sizes.
            </p>
          </div>
          <Divider />

          <div className="section" id="containers">
            <h2>Containers</h2>
            <p>
              Containers wrap content to either be fully fluid or center
              constrained. Fluid containers take up the 100% of the content left
              to right. Center-constrained containers adopt the maximum width
              depending on screen size. They then center that content
              horizontally. See the table below for more information.
            </p>
            <p>
              Bootstrap’s containers allow you to target a larger screen size
              and then gracefully switch to 100% fluid on smaller screen sizes.
            </p>
          </div>
          <Divider />

          <div className="section" id="column-gutters">
            <h2>Column Gutters</h2>
            <p>Gutters are the gaps in between columns. The default is 16px.</p>
            <p>
              Optionally, you can set gutters responsively at different
              breakpoints. For example, adding the classes: g-lg-1 g-xl-2 would
              give you a 16px gutter on XL widths, and 8px for all other smaller
              widths.
            </p>
          </div>
          <Divider />
        </React.Fragment>
      </FoundationPage>
    </Layout>
  );
}
